
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";
import { AppealsByContract } from "@/models/appeals";
import XDataTable from "../hoc/Table.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import XDropdownWithDatePicker from "../hoc/DropdownWithDatePicker.vue";
import XButton from "../SimpleButton.vue";
import { formatDate } from "@/lib/date";

@Component({
  components: {
    XDataTable,
    XDropdownWithDatePicker,
    XButton,
    AppApiMixin
  },
  computed: {
    ...mapState({ appEnvironment: "appEnvironment" }),
    ...mapGetters({ contractId: "contract/id" }),
  },
  methods: {
    ...mapMutations("appeals", {
      setCurrentAppealInfo: "setCurrentAppealInfo",
      setUnreadCounterValue: "setUnreadCounterValue"
    }),
    ...mapMutations("sidebar", {
      setCounterValue: "setCounterValue",
    }),
  },
  filters: {
    formatDate(value: string): string {
      return formatDate(value, "full");
    },
  },
})

class AppealsGrid extends Vue {
  [x: string]: any;

  currentDocTypeBySenderStatusIndex = 0;
  selectedAll = false;
  selectedRows: boolean[] = [];
  dates: string[] = [];

  @Prop({ required: true }) readonly appeals!: AppealsByContract[];

  public mounted() {
    this.$emit("updateTable");
  }

  headers = [
    { text: "Дата", value: "datta", width: "14%" },
    { text: "Номер", value: "nomer", width: "14%" },
    { text: "Обращение", value: "vidObr", width: "58%" },
    { text: "Результат", value: "status", width: "14%" },
    { text: "Непрочитано", value: "unread" },
    { text: "Уведомлять", value: "flag" },
    { text: "Ид", value: "id_main" },
  ];

  appKeys = [
    "датавремя",
    "номер",
    "содержание",
    "обращение-результат>название",
    "непрочитано",
    "обращение-результат>признаки",
    "$номерЗаписи"
  ];

  public get totalUsedAppKeys(): number {
    return !this.currentDocTypeBySenderStatusIndex ? this.appKeys.length : 4;
  }
  public get preparedHeaders() {
    return [
      ...this.headers.slice(0, this.totalUsedAppKeys),
      { text: "", value: "action", width: "7.5%" },
    ];
  }
  public get preparedAppeals() {
    const requiredKeys = this.appKeys.slice(0, this.totalUsedAppKeys);

    let app_p1 = Array.from(this.appeals).filter(item =>
      item['непрочитано'] > 0 && item['обращение-результат>признаки'] % 4 >= 2
    );

    this.setUnreadCounterValue(app_p1);
    this.setCounterValue(app_p1 ? app_p1.length : 0);

    let app_p2 = Array.from(this.appeals).filter(item =>
      item['обращение-результат>название'] == ''
    );
    let app_p3 = Array.from(this.appeals).filter(item =>
       item['обращение-результат>название'] != ''
    );

    let sorted_app = [];
    let set = new Set();
    app_p1.forEach((item) => {
      set.add(item);
    });
    app_p2.forEach((item) => {
      set.add(item);
    });
    app_p3.forEach((item) => {
      set.add(item);
    });

    set.forEach(item => sorted_app.push(item));

    return sorted_app.map((appeal, index) => {
        const appValues = requiredKeys.map((keyByTd) => {
          return Array.isArray(keyByTd) ? keyByTd.map((key) => appeal[key]) : appeal[keyByTd];
        });
      return [...appValues];
      // Пустая строка, как последний элемент массива позволяет использовать дропдаун
      // в каждой заключительной ячейке строки таблицы.
    });
  }
  public get tdClassesByHeaders() {
    const headerValues = this.headers.map(({ value }) => value);
    const tdClasses: { [headerValue: string]: string } = {};

    return headerValues.reduce((acc, headerValue) => {
      const headerId = headerValue.replace("document", "");
      let counter = 0;

      const headerIdKebabCase = headerId.replace(/[A-Z]/g, (match) => {
        const result = match.toLowerCase();
        return counter++ ? `-${result}` : result;
      });

      return {
        ...acc,
        [headerValue]: `data-table__td data-table__td_header_${headerIdKebabCase}`,
      };
    }, tdClasses);
  }
  public get runUpdate(): string {
    const {
      dates
    } = this;

    return [
      dates.join(" - ")
    ].join(" | ");
  }
  public get appealsLength(): number {
    return this.appeals.length;
  }
  public get runSelect(): string {
    return this.selectedRows.join(" ");
  }
  public get totalSelectedRows(): number {
    return this.selectedRows.filter((state) => state).length;
  }

  public get selectedRowsSum() {
    const { appeals, selectedRows } = this;

    return appeals.reduce(
        (acc, appeal, i) => (selectedRows[i] ? acc + appeal["номер"] : acc),
        0
    );
  }

  public setInfoAppealTo(number) {
    let asd1 = Array.from(this.appeals).find(item => item['$номерЗаписи'] == number);

    const formatDate = asd1['датавремя'].split('T')[0].split('-').reverse().join('.');
    this.setCurrentAppealInfo([{
      //'appealId': this.appeals[number]['$номерЗаписи'],
      'appealId': number,
      'appealDate': formatDate,
      'appealVidName': asd1['обращение-вид>название'],
      'appealText': asd1['содержание'],
      'appealSigns': asd1['обращение-результат>признаки'],
    }]);

    this.$router.push({name: 'appealChat', params: {contractId: this.contractId, appealId: number.toString()}});
    return '';
  }


  /**
   * Использовать значение вычисляемого свойства runUpdate в качестве сигнала
   * для запуска события "update:docsProps". Такой vue-трюк позволяет одновременно
   * контролировать значения нескольких свойств состояния.
   */
}

export default AppealsGrid;
